.main-container{
    display: grid;
    grid-template-rows: 1fr max-content;
    justify-items: center;
    margin-top: 10em !important;
}

.main-container-slogan{
    text-align: center;
}

.main-container-slogan p{
    font-size: 2em;
    margin-bottom: 1.6em !important;
}
.main-container p strong{
    color: #1DB954;
}

.landing-container .primary-button{
    font-size: 1.6em;
    background-color: #1DB954;
    padding: .8em 1.6em;
    color: #ffffff;
    font-weight: 600;
    border-radius: 8px;
}
.landing-container .primary-button:visited{
    color: #ffffff;
}
.landing-container .primary-button:hover{
    text-decoration: none;
}

@media screen and (min-width:660px) {
    .main-container{
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: none;
        align-items: center;
        margin-top: 10em !important;
    }
}