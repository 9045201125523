footer{
    background-color: #0e0e0e;
    color: #ffffff;
    padding: 12px;
    margin-top: 4em;
}
#about-us .landing-container a{
    color: #ffffff;
    font-size: 16px;
}
 .footer-container div{
    margin-bottom: 16px;
}
 .social-container{
    display: flex;
}
 .social-container li a{
    color: #ffffff;
    margin-right: 16px;
    font-size: 2.4em;
}
 #about-us .social-container li a:hover{
    cursor: pointer;
    color: #1DB954;
}
#about-us .social-container li a i{
    font-size: 2em;
}
 .footer-title{
    margin-top: 12px !important;
    margin-bottom: 0px;
    font-size: 1.8em !important;
    font-weight: 700;
}
 .footer-container{
    padding: 20px !important;
}

 .copyright-section{
    display: flex;
    justify-content: center;
    gap: 20px;
}
 .copyright-section img{
    width: initial;
}
 .footer-link a{
    color: #ffffff;
}
 #about-us .footer-link a:hover{
    cursor: pointer;
    color: #1DB954;
}
.footer-contact-container li a{
    color: #ffffff;
}
.footer-link{
    font-size: 16px;
}
@media screen and (min-width:660px) {
     .footer-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 8px;
        align-items: center;
        justify-items: center;
    }
}