.page-section{
    margin-top: 100px;
    padding-top: 50px;
}
.page-section p{
    margin-bottom: 0;
    font-size: 1.4em;
}
.page-section h1{
    font-size: 2.8em;
    margin-bottom: 12px;
    color: black;
    font-weight: 700;
}
.page-section h2{
    font-size: 2.1em;
    color: black;
    font-weight: 700;
    margin: 10px 0;
}
.page-section ul li{
    font-size: 1.2em;
    list-style: circle;
    margin-left: 2.8em;
}