.regulations-main-container{
    display: grid;
    row-gap: 1.2em;  
}
.regulations-main-container img{
    grid-row: 1/2;
    justify-self: center;
}
.regulations-subitem li{
    list-style: initial;
}
.regulation-title{
    font-size: 1.6em;
    display: inline;
    font-weight: 600;
}
.regulations-subitem {
    padding: 2em;
    font-size: 1.4em;
}

@media screen and (min-width:660px) {
    .regulations-main-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1.2em;
    }
    .regulations-main-container img{
        align-self: center;
        grid-column: 2/3;
    }
}