

:root {
  --primary: #28a745; 
 
  --light: #F2F2F2;
  --dark-primary: #ecebf1;
  --grey: #A6A6A6;
  --dark: #262626;
}
img{
  max-width: 100%;
  height: auto;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  background: radial-gradient(circle,#4EAD16 35% , #085924);
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-size: 16px !important;
}
.user-img{
  margin-top: 10px;
  margin-bottom: 10px;
}
.user-img img{
  width: 200px;
  height: 200px;
  box-shadow: 0px 0px 3px var(--primary);
  border-radius: 50%;
  
}
.sidebar.close {
  left: 0;
  animation: slide-closed 2.0s forwards;
}

.sidebar-close{
  background: none;
  border: none;
  font-size: 2rem;
}

@keyframes slide-open {
100%{left: 0}
}
@keyframes slide-closed {
  100%{left: -100%}
}

h1, h2, h3, h4, h5, h6 {
  color: var(--primary);
  font-weight: 700;
}

.sidebar-background{
  background: linear-gradient(225deg,#085924 10% , #4EAD16);
  width: 100px;
  position: fixed;
  height: 100vh;
  z-index: 100;
}
.content-side-open{
  padding-left: 100px;
}
#sidebar-container{
  /* background-color: var(--dark); */
  width: 101px;
}

.list-group-item{
  background: none !important;
  width: 100px;
  
  text-align: center;
  padding: 0;
  margin: 0;
}

.card-info{
  background-color: white;
  border-left: 5px solid var(--primary) !important;
  
}
.card-info-fixed{
  background-color: white;
  border: 5px solid var(--primary) !important;
  padding: 0 !important;
  
}
.card-info-icon{
  background-color: var(--primary);
  

}
hr{
  margin: 0;
  padding: 0;

}
.circle{
  background-color: var(--primary);
}
.color-green{
  background-color: var(--primary);
}

.logo-sidebar{
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 6px var(--light);
  border-radius: 50%;
}


.list-group-item:hover{
  background: var(--light) !important;
  color: black  !important;
}

.card-header{
  background-color: var(--primary);
}

.bg-light-blue {
  background-color: var(--light) !important;
}

.shadow-sm {
  box-shadow: 0 0 1.25rem var(--dark);
}

.shadow-hover {
  transition: 1s ease-in-out;
}

.shadow-hover:hover {
  transition: 1s ease-in-out;
  box-shadow: 0 .5rem 1rem var(--dark)!important;
}



#sidebar-container .logo{
  padding: 0.875rem 1.25rem;
}



#content-wrapper.toggled #sidebar-container {
  margin-left: 0;
}

.circle {
  width: 50px;
  height: 50px;
}

.badge {
  padding: .5rem 1rem;
}


.avatar {
  max-width: 35px;
}


.badge-secondary {
  background-color: var(--grey);
  color: var(--primary);
}

#content {
  min-height: 100vh;
  overflow-y: hidden;
  padding-left: 100px;
  overflow-x: hidden;
}
#not_fount {
  min-height: 100vh;
  overflow-y: hidden;  
  overflow-x: hidden;
}




@media (min-width: 768px) {
  #sidebar-container {
    margin-left: 0;
  }
  #content-wrapper.toggled #sidebar-container {
    margin-left: -18rem;
  }
}


.card{
  border: none;
  
}

.forgot{
  padding-top: 10px;
}



.button-action{
  width: 30px;
  height: 35px;
  margin-right: 3px;
  
}

.action{
  
  font-size: 20px !important;
  text-align: center !important;
 
}
input:disabled {
  border: 0;
  background: none;
  color: black;
}
select:disabled{
  border: 0;
  background: none;
  color: black;
}
.upgrade-row{
  overflow-x: hidden !important;
}

.upgrade-card {
  margin: 1rem;
  
  
}

.upgrade-card h5{
  color: black !important;
}

.prueba{
  font-size: 10px;
}

.card-img-top{
  height: 150px;
  width: 250px;
}


.price{
  box-shadow: 3px 2px 3px var(--dark)!important; 
}

.footer-login {
  width: 100%;
}
.container-login{
  min-height: 93vh !important; 
}

.preview-image{
  background-color: rgb(222, 222, 222);
  margin: 8px;
}
.preview-image img{
  width: 90px;
}

.skeleton-loader {
  background-color: #f2f2f2;
  animation: skeleton-loading 1s ease-in-out infinite alternate !important;
}

.card-header-issue{
  background-color: none !important;
  background: none !important;
}
.page-footer{
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
}
@keyframes skeleton-loading {
  0% {
    background-color: #f2f2f2;
  }
  50% {
    background-color: #e6e6e6;
  }
  100% {
    background-color: #f2f2f2;
  }
}
