:root {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  
}

 *,
 ::after,
 ::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.landing-container *{
  margin: 0;
}
.landing-container{
  font-size: 62.5%;
}
html{
  font-family: 'Roboto', sans-serif;
}
.landing-page-container{
  background-color: #ffffff;
}
img{
  max-width: 100%;
  height: auto;
  width: 400px;
}
li{
  list-style: none;
}
.landing-container a{
  text-decoration: none;
  color: initial;
}

.main-section{
  margin: 0 auto;
  padding: 1.2em;
  margin-top: 2em;
  max-width: 1000px;
}
.section-title{
  font-size: 2.6em;
  text-align: center;
  margin-bottom: 2.4em;
  color: #000000;
}
.landing-container a:visited{
  color: initial;
}

@media screen and (min-width:660px) {
  img{
    width: initial;
  }
}
