.landing-container .feature-card{
    height: 320px;
    min-width: 320px;
    padding: 2em;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-bottom: 1.2em;
    border: 1px solid rgb(212, 212, 212);
    /*box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);*/
}
.landing-container .feature-card:hover{
    cursor: pointer;
}
.landing-container .front-card{
    display: grid;
    align-items: center;
}
.landing-container .front-card img{
    max-width: 200px;
    justify-self: center;
}
.landing-container .back-card{
    display: flex;
    align-items: center;
}
.landing-container .back-card p{
    font-size: 1.4em;
}
.landing-container .section-subtitle{
    font-size: 2em;
    text-align: center;
    margin-bottom: 1.6em;
    font-weight: 600;
}
.landing-container .accessibility-features-container{
    margin-bottom: 4em;
}
.landing-container .card-details-title{
    text-align: center;
    margin-top: 1.2em;
    font-size: 1.8em;
    font-weight: 600;
}
@media screen and (min-width:660px) {
    .landing-container .details-main-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 4em;
    }
    .landing-container .front-card img{
        max-width: 150px;
    }
    .landing-container .feature-card{
        height: 250px;
        grid-template-rows: none;
        grid-template-columns: 150px 1fr;
        margin-bottom: 2em;
    }
    .landing-container .feature-card-img{
        grid-column: 1 / 2;
    }
    .landing-container .accessibility-features-container{
        margin-bottom: 0em;
    }
}