.characteristics-cards-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    column-gap: 1.6em;
    row-gap: 2em;
    justify-items: center;
}
.characteristics-card{
    display: grid;
    grid-template-rows: 170px max-content;
    margin: 0 auto;
    row-gap: 1.6em;
    border-radius: 20px;
    padding: 1.8em 1.6em;
    /*box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);*/
    border: 1px solid rgb(212, 212, 212);
    margin-bottom: 1.2em;
    max-width: 300px;
    transition: all .5s;
}
.characteristics-card img{
    height: 100%;
}
.characteristics-card p{
    font-size: 1.6em;
    text-align: center;
}