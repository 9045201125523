.contact-container{
    display: grid;
    row-gap: 1.2em;  
    
}
.contact-container img{
    grid-row: 1/2;
    justify-self: center;
}
.contact-form-container{
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}
.contact-form-container textarea{
    resize: none;
    font-family: var(--font-family);
}
.contact-form-container input,
.contact-form-container textarea{
    padding: 1.2em 1.6em;
    margin-bottom: 1.2em;
    font-size: 1.4em;
    border-radius: 8px;
    border: 1px solid rgb(197, 197, 197);
    outline: none;
}
.contact-form-container input:focus,
.contact-form-container textarea:focus{
    outline: none;
}

.contact-form-container input[type="submit"]{
    color: #ffffff;
    background-color: #1DB954;
    border: none;
}
.contact-form-container input[type="submit"]:hover{
    cursor: pointer;
}


@media screen and (min-width:660px) {
    .contact-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1.2em;
    }
}