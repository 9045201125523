.header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: minmax(150px,200px) 1fr;
    align-items: center;
    height: 10em;
    padding: .8em;
    box-shadow: 0px 13px 15px -3px rgba(0,0,0,0.1);
    background-color: #ffffff;
    z-index: 100;
}
.header-logo{
    display: flex;
    align-items: center;
    min-width: 80px;
}
.header-logo a{
    display: flex;
    align-items: center;
    color: #000000;
}
.header-logo p{
    margin-left: 1em;
    font-size: 1.6em;
    font-weight: 600;
    word-wrap: break-word;
}
.landing-container .header-logo img{
    width: 60px;
    height: 60px;
}
.menu-button{
    font-size: 2.5em;
    justify-self: end;
}
.header-navbar{
    position: fixed;
    background-color: #ffffff;
    top: 0;
    left: 100%;
    width: 100vw;
    height: 0;
    transition: all .3s;
}
.header-navbar--active{
    left: 0;
    height: 100vh;
}
.navbar-item{
    padding: 1.2em;
    text-align: center;
    border-bottom: 1px solid rgb(212, 212, 212);
}
.navbar-item a{
    font-size: 1.6em;
    color: #000000;
}
.navbar-item a:hover{
    text-decoration: none;
    color: #1DB954;
}

.navbar-item-close{
    color: #000000;
    text-align: end;
    font-size: 2em;
}

@media screen and (min-width:660px) {
    
    .header-logo{
        margin-left: 2em;
    }
    .menu-button{
        display: none;
    }
    .header-navbar{
        position: static;
        grid-column: 2/3;
        justify-self:end;
        width: 100%;
        max-width: 700px;
        height: auto;
        background-color: transparent;
    }
    .navbar-container{
        display: flex;
        justify-content: space-around;
    }
    .navbar-item{
        border: none;
    }
    .navbar-item-close{
        display: none;
    }
    .navbar-item{
        padding: 0;
        
    }
    .landing-container .navbar-item:hover{
        border-bottom: 1px solid #4ead16;
    }
}